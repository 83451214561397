import React from 'react';
import { Props } from './types';

const Medium: React.FC<Props> = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 23 24'
      {...props}
    >
      <path
        fill='#73808D'
        // eslint-disable-next-line max-len
        d='M0 0h22.157v24H0V0zm14.529 5.684c-.978 2.626-1.955 5.26-2.95 7.94-1.094-2.571-2.172-5.08-3.224-7.606-.117-.28-.243-.352-.493-.352-1.253.01-2.507 0-3.76.01-.092 0-.184.017-.342.035.1.19.15.343.242.46.36.47.71.948 1.086 1.399.259.307.36.622.36 1.047a341.476 341.476 0 000 6.54c.008.551-.134.975-.46 1.372-.426.506-.81 1.047-1.203 1.588-.084.118-.117.271-.184.451h4.612c-.076-.171-.117-.306-.192-.406-.468-.64-.961-1.263-1.412-1.921-.142-.208-.268-.497-.268-.75a316.64 316.64 0 01-.008-5.683c0-.109.008-.217.025-.46l.635 1.488c1.06 2.508 2.122 5.026 3.191 7.525.042.1.21.198.31.198.108 0 .233-.117.317-.216.058-.072.05-.199.084-.298l1.955-5.332c.392-1.083.793-2.157 1.194-3.24.059 1.895-.017 3.781.084 5.649.067 1.263-.176 2.255-1.17 2.977-.108.081-.15.262-.25.451h6.065c-.1-.171-.142-.297-.226-.397-.292-.333-.576-.667-.902-.965-.226-.207-.284-.433-.284-.74.008-2.454.075-4.908-.025-7.353-.05-1.254.167-2.247 1.17-2.969.108-.08.158-.261.267-.45-1.479.008-2.849.008-4.244.008z'
      />
    </svg>
  );
};

export default Medium;
